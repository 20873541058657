$sidebar-bg: #2F343C;
$sidebar-width: 250px;
$sidebar-font-color: #CED9E0;
$sidebar-active-link-bg: #1C2127;

.logo-block {
  width: 180px;
}

.workspace {
  width: 100vw;
  height: calc(100vh - 50px);
  overflow: hidden;

  display: flex;
  flex-direction: row;

  &__sidebar {
    height: 100%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    color: $sidebar-font-color;
    background-color: $sidebar-bg;

    a {
      color: $sidebar-font-color;
    }

    a:hover {
      text-decoration: none;
      color: $sidebar-font-color;
    }
  }

  &__sidebar-header {
    display: flex;
    align-items: center;
    height: 50px;
    box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
    padding: 0 15px;
    font-size: 1rem;
    font-weight: 900;
  }

  &__sidebar-content {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  &__sidebar-nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    font-size: 16px;
    font-weight: 700;

    &.active {
      border-radius: 4px;
      background-color: $sidebar-active-link-bg;
    }
  }

  &__sidebar-nav-sublink {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    font-size: 16px;
    font-weight: 700;
    margin-left: 32px;

    &.active {
      border-radius: 4px;
      background-color: $sidebar-active-link-bg;
    }
  }

  &__sidebar-link-icon {
    padding: 0 0 0 4px;
  }

  &__sidebar-link-name {
    padding: 2px 0 0 8px;
  }

  &__page-container {
    //width: calc(100vw - $sidebar-width);
    width: 100%;
    height: calc(100vh - 50px);
    overflow: auto;
    background-color: #1C2127;
  }
}

.application {
  height: 100vh;
  width: 100%;
}
