$bootstrap_bg: #30404d;
$bootstrap_font_color: #CED9E0;

.bootstrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  color: $bootstrap_font_color;
  background-color: $bootstrap_bg;

  &__content {
    width: 100%;
    padding: 1rem;
    text-align: center;
    max-width: 25rem;
  }

  &__message {
    font-size: 1rem;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__vendor {
    font-size: 2.5rem;
    font-weight: 900;
    letter-spacing: 8px;
  }
}

.login-form {
  &__signin-button {
    margin-top: 20px;
  }
}
