.stock-view {
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .positive-grow {
    color: #72CA9B;
    font-weight: 500;
  }

  .negative-grow {
    color: #E76A6E;
    font-weight: 500;
  }

  .cards-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    .daily-stat-table {
      font-size: 12px;
    }
  }
}