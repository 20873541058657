.share-view {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .logo-name {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .logo-large {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-size: 100%;
      padding-right: 32px;
    }
  }

  .dividends-futures-block {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .dividends-block {
      width: 25%;
    }

    .dividends-table {
      font-size: 12px;
      width: 100%;
    }
  }

  .share-chart {
    height: 400px;
  }


  .share-title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;

    .price-value-header {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      font-size: 60px;
      font-weight: 500;
    }

  }

  table.clusters-table {
    font-size: 10px;

    td {
      text-align: center;
      padding: 4px 5px;
      font-weight: 500;
    }

    .more-buy {
      color: #72CA9B;
    }

    .more-sell {
      color: #E76A6E;
    }
  }

  .open-price {
    border-left: 3px solid #1fbee8;
    background-color: #222222 !important;
  }

  .close-price {
    border-right: 3px solid #1fbee8;
    background-color: #222222 !important;
  }

}

.max-qty {
  border: 1px solid #CED9E0;
}
