.ideas-view {
  padding: 16px;

  .logo-small {
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 50%;
    background-size: 100%;

  }

  .positive-grow {
    color: #72CA9B;
    font-weight: 500;
    white-space: nowrap;
    padding: 0 8px;
  }

  .negative-grow {
    color: #E76A6E;
    font-weight: 500;
    white-space: nowrap;
    padding: 0 8px;
  }

  .ideas-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__table {
    width: 100%;
  }

  td.target-price-cell {
    max-width: 8rem;
    width: 8rem;
  }

  td.ticker-cell {
    max-width: 4rem;
    width: 4rem;
    text-align: center;
  }

  td.upside-cell {
    max-width: 6rem;
    width: 6rem;
  }

  @media screen and (min-width: 0px) and (max-width: 700px) {
    .dividend-cell {
      display: none;
    }

    td.issuer-cell {
      max-width: 10rem;
      width: 10rem;
      overflow: hidden;
    }

    .current-price-cell {
      max-width: 8rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .description-cell {
      display: none;
    }

  }

  @media screen and (min-width: 701px) and (max-width: 3000px) {
    .dividend-cell {
      max-width: 14rem;
      width: 14rem;
    }

    td.issuer-cell {
      max-width: 20rem;
      width: 20rem;
    }

    .current-price-cell {
      max-width: 12rem;
      width: 12rem;
    }

    .decision-cell {
      max-width: 6rem;
      width: 6rem;
    }
  }

  .description-cell {
    font-size: 14px;
  }

  .decision-cell.decision-buy {
    background-color: #165A36 !important;
  }

  .decision-cell.decision-hold {
    background-color: #77450D !important;
  }

  .decision-cell.decision-not-hold {
    background-color: #AC2F33 !important;
  }
}