.shares-view {
  padding: 1rem;

  .positive-grow {
    color: #72CA9B;
    font-weight: 500;
    white-space: nowrap;
    padding: 0 8px;
  }

  .negative-grow {
    color: #E76A6E;
    font-weight: 500;
    white-space: nowrap;
    padding: 0 8px;
  }

  @media screen and (min-width: 0px) and (max-width: 700px) {
    .current-price-cell {
      max-width: 8rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .level-cell {
      display: none;
    }

    .positive-grow {
      white-space: pre;
    }

    .negative-grow {
      white-space: pre;
    }
  }

  @media screen and (min-width: 701px) and (max-width: 3000px) {
    td.current-price-cell {
      max-width: 12rem;
    }

    .current-price-cell {
      max-width: 12rem;
      width: 12rem;
    }
  }
}